export const environment = {
  production: true,
  env: 'production',
  baseUrl: 'https://api.supercmms.com/',
  fileRepositoryUrl: 'https://assets.supercmms.com',
  mapBoxAccessKey: 'pk.eyJ1Ijoia2lzaG9yLWRlIiwiYSI6ImNsMnEwMGRiazJwcDUzZXFoYXo5cjE3dncifQ.IKRrcZtD98B6dsjS3B3ixA',
  featureToggles: {},
  firebaseConfig: {
    apiKey: "AIzaSyDszF-n5yOWArNRkFdwS2FbaL7hmRNPmZg",
    authDomain: "super-cmms-production.firebaseapp.com",
    projectId: "super-cmms-production",
    storageBucket: "super-cmms-production.appspot.com",
    messagingSenderId: "97881876860",
    appId: "1:97881876860:web:559b0a0efe39b3524ddcf9",
    measurementId: "G-73KMJSJBHV"
  },
  RECAPTCHA_V3_KEY: '6LdS--QhAAAAAGLFt7hLbQm-w6eF22EI7nZwLKzn',
  RECAPTCHA_V2_KEY: '6LdT_eQhAAAAAE9CYf4ywNWaMbBad_HxFWD1kGTj',
  STRIPE_KEY: 'pk_live_51LJY7kSJpHc8ilTFuwgFHefQ5l7EMJkXHkg1MZPrAbKh7cFqgfxrd3AD0KWywMrTY5ctEfTGMtMdwBSP1QyAStzm00o5tuy4dZ'
};
