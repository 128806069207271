import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import {  ServerErrorMessage } from 'src/app/util/common.constants';
import { AppService } from 'src/app/app.service';

@Injectable()
export class AppInterceptor implements HttpInterceptor {

  constructor(
    private messageService: NzNotificationService,
    private appService: AppService,
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.method === 'GET') {
      request = request.clone({
        setParams: {
          companyId: localStorage.getItem('companyId')?.toString() || '',
          rootLocation: localStorage.getItem('rootLocation')?.toString() || ''
        },
        setHeaders: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('AUTH') || '',
        }
      });
    } else if(request.method === 'POST' || request.method === 'DELETE') {
      const companyId = request.body.companyId || localStorage.getItem('companyId')?.toString() || '' 
      request = request.clone({
        body: { ...request.body, companyId, rootLocation: localStorage.getItem('rootLocation')?.toString() || ''},
        setHeaders: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('AUTH') || '',
        }
      });
    }
    if (request && (
      request.url.indexOf('localhost') > -1 ||
      request.url.indexOf('staging-api.supercmms.com') > -1 ||
      request.url.indexOf('api.supercmms.com') > -1)) {
      request = request.clone({
        withCredentials: true
      });
    }
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        let errorMessage = ServerErrorMessage;
        if (error.error instanceof ErrorEvent) {
          errorMessage = `${error.error.message}`;
        } else if (error.error && error.error.message){
          errorMessage = `${error.error.message}`;
        } else if (error.error && error.error.error && error.error.error.message){
          errorMessage = error.error.error.message;
        } else {
          errorMessage = ServerErrorMessage;
        }
        this.messageService.create('error', 'Error', errorMessage, { nzClass: 'error' });
        if (error.error && error.error.logout === true) {
          this.appService.logout();
        }
        return throwError(() => new Error(errorMessage));
      })
    );
  }
}
