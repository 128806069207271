<div class="popup-window">
  <div class="heading">{{heading}}</div>
  <div *ngIf="rules['password']">
    <div class="rule" [hidden]="rules['password'].type !== 'number'"
      [ngClass]="{'rule-pass':passwordStatus['password']}">
      Password length should be {{rules['password'].length}} characters.
    </div>
    <div class="rule" [hidden]="rules['password'].type !== 'min'"
      [ngClass]="{'rule-pass':passwordStatus['password']}">
      Password length should be minimum {{rules['password'].min}} characters.
    </div>
    <div class="rule" [hidden]="rules['password'].type !== 'range'"
      [ngClass]="{'rule-pass':passwordStatus['password']}">
      Password length should be {{rules['password'].min}} - {{rules['password'].max}}
      characters.
    </div>
  </div>
  <div class="rule" [hidden]="!rules['include-symbol']" [ngClass]="{'rule-pass':passwordStatus['include-symbol']}">
    Include Symbols: (e.g. @#$%)
  </div>
  <div class="rule" [hidden]="!rules['include-number']" [ngClass]="{'rule-pass':passwordStatus['include-number']}">
    Include Numbers: (e.g. 123456)
  </div>
  <div class="rule" [hidden]="!rules['include-lowercase-characters']"
    [ngClass]="{'rule-pass':passwordStatus['include-lowercase-characters']}">
    Include Alphabets: (e.g. abcdefgh or ABCDEFGH)
  </div>
  <div class="rule" [hidden]="!rules['include-uppercase-characters']"
    [ngClass]="{'rule-pass':passwordStatus['include-uppercase-characters']}">
    Include Uppercase Characters:(e.g. ABCDEFGH )
  </div>
  <!-- <div class="success-message" [hidden]="!isSecure">
    {{successMessage}}
  </div> -->
</div>
